import React from 'react'

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <p className="wow fadeInLeft" data-wow-delay=".2s">
              &copy; APGT – Associação Portuguesa de Gadgets e Tecnologia
            </p>
          </div>

          <div className="col-sm-6">
            {/* <!-- Footer Social Icons --> */}
            <ul className="footer-social wow fadeInRight" data-wow-delay=".2s">
              <li>
                <a href="https://www.facebook.com/">
                  <i className="ion-social-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/">
                  <i className="ion-social-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.yotutube.com/">
                  <i className="ion-social-youtube"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/">
                  <i className="ion-social-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
