import React from 'react'

export default function ContactUs() {
  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <div className="section-header text-center">
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            CONTACTE-NOS
          </h2>
          <br />
          <h3 className="wow fadeInUp" data-wow-delay=".2s">
            Email: geral@apgt.pt &nbsp;&nbsp; Tlf: 21 727 40 30
          </h3>
          <br />
          APGT - Associação Portuguesa de Gadgets e Tecnologia
          <br />
          Rua Pedro e Inês, Lote 3, Piso 2, Esc. 11
          <br />
          1990-074 Lisboa
          <br />
          <br />
          <br />
          <p className="wow fadeInUp" data-wow-delay=".4s">
            Para mais informações preencha o formulário de contacto
          </p>
        </div>

        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <form
              id="contact-form"
              name="contactForm"
              className="contact-form"
              data-toggle="validator"
            >
              <div id="contact-form-result"></div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control wow fadeInUp"
                      data-wow-delay=".4s"
                      name="name"
                      autoComplete="on"
                      placeholder="Nome"
                      required
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control wow fadeInUp"
                      data-wow-delay=".5s"
                      name="email"
                      placeholder="Email"
                      autoComplete="on"
                      required
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control wow fadeInUp"
                  data-wow-delay=".6s"
                  name="subject"
                  placeholder="Telefone"
                  required
                />
                <div className="help-block with-errors"></div>
              </div>

              <div className="form-group">
                <textarea
                  className="form-control wow fadeInUp"
                  data-wow-delay=".8s"
                  name="message"
                  placeholder="Mensagem"
                  rows={5}
                  required
                ></textarea>
                <div className="help-block with-errors"></div>
              </div>

              <div className="form-group text-center">
                <button
                  type="submit"
                  className="btn-custom wow fadeInUp"
                  data-wow-delay="1s"
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
