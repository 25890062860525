import React, { useEffect, useState } from 'react'

import NavBar from './navBar'

// Make sure to use the correct path to your NavBar component

export default function WhoAreWe() {
  const [isScrolled, setIsScrolled] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const shouldScroll = scrollPosition > 0 // You can adjust this threshold

      setIsScrolled(shouldScroll)
    }

    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []) // Empty dependency array ensures the effect runs once

  return (
    <>
      <NavBar isScrolled={isScrolled} />
      <section
        id="intro"
        className="main-section parallax"
        data-parallax="scroll"
        data-image-src="bg-main.jpg"
        style={{ backgroundImage: 'url(bg-main.jpg)' }}
      >
        {/*       <!-- Particles Background --> */}
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="intro-text">
                <h1 className="wow fadeInUp" data-wow-delay=".2s">
                  <b>APGT</b>
                  <br />
                  Associacão Portuguesa de Gadgets e Tecnologia
                </h1>
                <div className="btns">
                  <a
                    href="#apgt"
                    className="app-btn wow bounceIn"
                    data-wow-delay=".6s"
                  >
                    QUEM SOMOS
                  </a>

                  <a
                    href="#contact"
                    className="app-btn wow bounceIn"
                    data-wow-delay=".8s"
                  >
                    CONTACTOS
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
