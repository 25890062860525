import './css/bootstrap.min.css'
import './css/animate.min.css'
import './css/ionicons.min.css'
import './css/linea.css'
import './css/magnific-popup.css'
import './css/owl.carousel.css'
import './css/style.css'
import './css/waves.min.css'

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import AdminPage from './modules/backOffice'
import Footer from './modules/laindingPage/sections/footer'
import LandingPage from './modules/laindingPage'
import { PageNotFound } from './modules/errors'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import useDocumentTitle from './useDocumentTitle'

function App() {
  function LandingPageWithTitle() {
    useDocumentTitle('APGT-Associacão Portuguesa de Gadgets e Tecnologia')
    return <LandingPage />
  }
  function AdminPageWithTitle() {
    useDocumentTitle('APGT-(Admin Page)')
    return <AdminPage />
  }
  return (
    <>
      <div className="body100vh">
        <div className="pace-overlay"></div>
        <ToastContainer autoClose={2000} />
        <Router>
          <Routes>
            <Route path="/" element={<LandingPageWithTitle />} />
            <Route path="/admin" element={<AdminPageWithTitle />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
        <ToastContainer />
      </div>
      <Footer />
    </>
  )
}

export default App
