import React, { useState } from 'react'

import LoginModal from '../modals/login'

export default function AdminPage() {
  const [adminData /* setAdminData */] = useState({
    users: [
      { id: 1, name: 'Admin User 1' },
      { id: 2, name: 'Admin User 2' },
      { id: 3, name: 'Admin User 3' },
    ],
    products: [
      { id: 101, name: 'Product A' },
      { id: 102, name: 'Product B' },
      { id: 103, name: 'Product C' },
    ],
    // Add other admin-related data as needed
  })
  const [isLoginModalOpen, setLoginModalOpen] = useState(true)
  const [loggedInUser, setLoggedInUser] = useState<string | null>(null)
  console.log(isLoginModalOpen)
  const handleLogin = (username: string) => {
    // Set the logged-in user
    setLoggedInUser(username)
  }

  const handleLogout = () => {
    // Log out the user
    setLoggedInUser(null)
  }

  return (
    <div style={{ minHeight: '93.4vh' }}>
      {!isLoginModalOpen && (
        <LoginModal
          onClose={() => setLoginModalOpen(false)}
          onLogin={handleLogin}
        />
      )}
      {loggedInUser ? (
        <div>
          <p>Welcome, {loggedInUser}!</p>
          <button onClick={handleLogout}>Logout</button>
          <div>
            <h2>Admin Page</h2>

            {/* Display admin data */}
            <div>
              <h3>Users</h3>
              <ul>
                {adminData.users.map((user) => (
                  <li key={user.id}>{user.name}</li>
                ))}
              </ul>
            </div>

            <div>
              <h3>Products</h3>
              <ul>
                {adminData.products.map((product) => (
                  <li key={product.id}>{product.name}</li>
                ))}
              </ul>
            </div>

            {/* Add other admin-related components or actions as needed */}
          </div>
        </div>
      ) : (
        <div style={{ minHeight: '93.4vh' }}>
          <button onClick={() => setLoginModalOpen(true)}>Login</button>

          {/* Add other admin-related components or actions as needed */}
        </div>
      )}
    </div>
  )
}
