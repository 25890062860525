import React from 'react'

export default function Objectives() {
  return (
    <section id="features-2" className="mockup-section section-2">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="mockup-text">
              <h2 className="wow fadeInUp" data-wow-delay=".4s">
                Objetivos
              </h2>

              <p className="wow fadeInUp" data-wow-delay=".6s">
                A Associação Portuguesa de Gadgets e Tecnologia (APGT) é uma
                organização sem fins lucrativos, de pessoas individuais e
                coletivas, que visa a promoção, a investigação, desenvolvimento
                e inovação de gadgets e tecnologias.
                <br />
                <br />
                <br />
                <strong>OS PRINCIPAIS OBJETIVOS</strong>
                <br />
                • Apoiar as empresas, outras entidades e profissionais que
                prossigam atividade na área dos gadgets e tecnologias.
                <br />
                • Promover e desenvolver os profissionais da área dos gadgets e
                tecnologias.
                <br />
                • Apoiar entidades parceiras e todas as startup&apos; s e
                atividades de empreendedorismo, inovação e desenvolvimento na
                área dos gadgets e tecnologias. <br />
                • Procurar incentivos, governamentais ou privados, para
                pesquisa, investigação e desenvolvimento, para potenciar novos
                lançamentos na área de gadgets e tecnologia. <br />
                • Promover e validar a certificação e ou acreditação dos gadgets
                e produtos tecnológicos nacionais. <br />
                • Promover, a nível internacional, os gadgets e produtos
                tecnológicos que são desenvolvidos em território da República
                Portuguesa. <br />
                • Promover a melhoria da qualidade dos gadgets e produtos
                tecnológicos nacionais assim como a sua divulgação internacional
                potenciando a sua entrada em mercados internacionais.
                <br />
                <br />
                <strong>AS PRIORIDADES ESTRATÉGICAS</strong>
                <br />
                • Constituir bases de conhecimento e intercâmbio sobre os
                gadgets e tecnologia.
                <br />
                • Promover atividades de divulgação e esclarecimento sobre o
                setor.
                <br />
                • Promover cursos, conferências, seminários e outras atividades
                similares bem como participar em eventos desta natureza. <br />
                • Desenvolver analises e estudos que contribuam para um melhor e
                a mais aprofundado conhecimento sobre o setor dos gadgets e
                tecnologia. <br />
                • Apoiar e incentivar atividades de investigação e
                desenvolvimento no setor, assim como dos seus associados. <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
