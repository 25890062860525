import React from 'react'

export const PageNotFound = () => {
  return (
    <>
      <a href="/">
        <img
          src="https://i.ibb.co/sF5NSn7/page-Not-Found.png"
          alt="page-Not-Found"
        />
      </a>
    </>
  )
}
