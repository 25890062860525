import React from 'react'

export default function Affiliates() {
  return (
    <section id="pricing" className="pricing-section">
      <div className="container">
        <div className="section-header text-center">
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            ASSOCIADOS
          </h2>
          A<b>APGT - Associação Portuguesa de Gadgets e Tecnologia</b>{' '}
          destina-se a todos os profissionais, docentes, estudantes, formadores,
          consultores, investigadores e todas as outras entidades envolvidas no
          sector tecnológico.
          <br />
          <br />
          <br />
          <br />
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            Sócios Institucionais
          </h2>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="p-table wow fadeInRight" data-wow-delay=".8s">
              <div className="header">
                <h4>Micro-Empresa</h4>
                <div className="price">
                  <span className="amount">120€</span>
                  <span className="period">/ano</span>
                </div>
              </div>
              <ul className="items">
                <li>10 trabalhadores</li>
              </ul>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdHGnZPwCa-c1n-OEtL9uAee2PK1DrLHAwv5qA1oe2AenUXhw/viewform?c=0&w=1"
                target="_blank"
                className="pricing-btn arrow-btn"
                rel="noreferrer"
              >
                Formulário de inscrição
                <span className="arrow"></span>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-table standard wow bounceIn" data-wow-delay=".2s">
              <div className="header">
                <h4>Pequena Empresa</h4>
                <div className="price">
                  <span className="amount">240€</span>
                  <span className="period">/ano</span>
                </div>
              </div>
              <ul className="items">
                <li>{'10 e <=50 trabalhadores'}</li>
              </ul>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdHGnZPwCa-c1n-OEtL9uAee2PK1DrLHAwv5qA1oe2AenUXhw/viewform?c=0&w=1"
                target="_blank"
                className="pricing-btn arrow-btn"
                rel="noreferrer"
              >
                Formulário de inscrição
                <span className="arrow"></span>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-table wow fadeInLeft" data-wow-delay=".8s">
              <div className="header">
                <h4>Média e Grande</h4>
                <div className="price">
                  <span className="amount">300€</span>
                  <span className="period">/ano</span>
                </div>
              </div>
              <ul className="items">
                <li>250 trabalhadores ou/e Multinacionais</li>
              </ul>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdHGnZPwCa-c1n-OEtL9uAee2PK1DrLHAwv5qA1oe2AenUXhw/viewform?c=0&w=1"
                target="_blank"
                className="pricing-btn arrow-btn"
                rel="noreferrer"
              >
                Formulário de inscrição
                <span className="arrow"></span>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="mockup-text">
            Sócios Individuais - Quota Anual: 12€
            <br />
            Quota Voluntária: 2€
            <br />
            <br />
            <br />
            <p className="wow fadeInUp" data-wow-delay=".6s">
              <strong>Preencha o nosso formulário de inscrição</strong>
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdHGnZPwCa-c1n-OEtL9uAee2PK1DrLHAwv5qA1oe2AenUXhw/viewform?c=0&w=1"
              target="_blank"
              className="btn-custom arrow-btn wow fadeInUp"
              data-wow-delay=".8s"
              rel="noreferrer"
            >
              FORMULÁRIO DE INSCRIÇÃO
              <span className="arrow"></span>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
