import React from 'react'

interface IProps {
  isScrolled: boolean
}

export default function NavBar({ isScrolled }: IProps) {
  const navStyles = {
    backgroundColor: isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent',
    transition: 'background-color 0.2s ease-in-out',
    boxShadow: isScrolled ? '0 0 10px rgba(0, 0, 0, 0.2)' : 'none',
    padding: '10px 0',
    color: isScrolled ? 'black' : 'white',

    // Add other styles as needed
  }
  const navTextStyles = {
    color: isScrolled ? 'black' : 'white',

    // Add other styles as needed
  }
  return (
    <nav className="navbar navbar-fixed-top" style={navStyles}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#nav-collapse"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a href="#" className="navbar-brand">
            {isScrolled ? (
              <img src="/logo-dark.png" alt="logo AGPT" />
            ) : (
              <img src="/logo-white.png" alt="logo AGPT" />
            )}
          </a>
        </div>

        <div className="collapse navbar-collapse" id="nav-collapse">
          <ul className="nav navbar-nav navbar-right">
            <li className="active">
              <a href="#intro" style={navTextStyles}>
                HOME
              </a>
            </li>

            <li>
              <a href="#apgt" style={navTextStyles}>
                APGT
              </a>
            </li>
            <li>
              <a href="#orgaos" style={navTextStyles}>
                Orgãos Sociais
              </a>
            </li>
            <li>
              <a href="#pricing" style={navTextStyles}>
                ASSOCIADOS
              </a>
            </li>
            <li>
              <a href="#contact" style={navTextStyles}>
                CONTACTOS
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
