import React from 'react'

export default function Statutes() {
  return (
    <section id="features-2" className="mockup-section section-2">
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ alignSelf: 'center' }}>
            <div className="mockup-text">
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Conheça os estatutos da APGT para mais informações,
                contacte-nos.
              </p>

              <a
                href="estatutosAPGT.pdf"
                target="_blank"
                className="btn-custom arrow-btn wow fadeInUp"
                data-wow-delay=".8s"
              >
                VER ESTATUTOS
                <span className="arrow"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
