import React from 'react'

export default function SocialEntities() {
  return (
    <section id="orgaos" className="features-2-section">
      <div className="container">
        <div className="section-header text-center">
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            Orgãos Sociais
          </h2>
          <div className="row">
            <div className="col-md-6">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".2s">
                <div className="content">
                  <h4>Comissão Directiva</h4>
                  <img
                    className="phone wow fadeInUp"
                    data-wow-duration=".8s"
                    data-wow-delay=".2s"
                    src="vitor_rosa.png"
                    alt=""
                  />{' '}
                  <img
                    className="phone wow fadeInUp"
                    data-wow-duration=".8s"
                    data-wow-delay=".2s"
                    src="filipe_barbosa.jpeg"
                    alt=""
                  />
                  <p>
                    • Fundador: Vitor Rosa – Presidente
                    <br />
                    <br />
                    • Fundador: Filipe Barbosa – Vice-Presidente
                    <br />
                    • Joana Rosa – Vice-Presidente
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".4s">
                <div className="content">
                  <h4>Mesa da Assembleia</h4>
                  <img
                    className="phone wow fadeInUp"
                    data-wow-duration=".8s"
                    data-wow-delay=".2s"
                    src="carlos_pereira.png"
                    alt=""
                  />
                  <p>
                    • Carlos Pereira – Presidente
                    <br />
                    <br />
                    • Francilia Teixeira – Vice-Presidente
                    <br />
                    • Gonçalo Silva - Secretário
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".6s">
                <div className="content">
                  <h4>Conselho Fiscal</h4>
                  <img
                    className="phone wow fadeInUp"
                    data-wow-duration=".8s"
                    data-wow-delay=".2s"
                    src="pedro_boavida.png"
                    alt=""
                  />
                  <p>
                    • Pedro Boavida – Presidente
                    <br />
                    <br />
                    • Rita Maurício – Vogal
                    <br />
                    • Joana Carvalho – Vogal <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
